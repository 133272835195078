import React from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileImage from "../account/MobileImage"
import Image from "../../../globals/classes/shared/Image"
/**
 *
 * @param {Object} props
 * @param {Verification} props.verification
 * @param {boolean} props.canDelete
 * @param {function} props.onDelete
 * @return {JSX.Element}
 * @constructor
 */
const MobileIdentityVerificationCard = props => {
	return <div className={"paralian-mobile-info-card"}>
		<div style={{display: "inline-flex", maxWidth: "36vh", width: "100%"}}>
			<MobileImage image={new Image({path: props.verification.image.path})} size={"10vh"} alt={"logo"}
				style={{margin: "auto 1vh"}} id={"id-verification-card-img"}/>
			<div style={{padding: "2vh", textAlign: "right", width: "100%"}}>
				<div style={{fontSize: "2.5vh"}}>
					{props.verification.name}
				</div>
				<div style={{fontSize: "2vh", opacity: "0.5"}}>
					<i>{`${translate(DICTIONARY.STATUS.X)}: ${translate(props.verification.status)}`}</i>
				</div>
				{/* props.canDelete ?
					<div style={{fontSize: "1.5vh"}} onClick={async () => {
						const confirmed = window.confirm("Would you like to cancel this verification request?")
						if (confirmed) {
							
							const response = await cancelVerificationRequest(props.verification.id)
							
							if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
								props.onDelete(props.verification.id)
							}
						}}}>
						<i>{"Cancel Request"}</i>
						<MobileFontAwesome icon={ICONS.BAN} style={{marginLeft: "1vh"}}/>
					</div> : <></> */}
			</div>
		</div>
	</div>
}

export default MobileIdentityVerificationCard