import React from "react"
import ICONS from "../../../globals/constants/client/icons"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import Modal from "../../../globals/components/Modal"
import MobileImage from "../account/MobileImage"
import Image from "../../../globals/classes/shared/Image"
import ancreGoldRedDot from "../../../images/ancre_logo_circle_gold_red_dot.svg"
/**
 *
 * @param {Object} props
 * @param {string} props.product
 * @param {Object} props.paralianProducts
 * @param {function} props.onClick
 * @return {JSX.Element}
 * @constructor
 */
const MobileParalianProductPricing = props => {
	/* TODO: update descriptions */
	try {
		let title, message, image, product, price, free
		const formatNumber = number => number.toLocaleString(undefined, {maximumFractionDigits: 0})
		const tableRow = unitPrice => <tr>
			<td>{unitPrice.lastUnit === 10e10 ? formatNumber(unitPrice.firstUnit) + "+" :
				`${formatNumber(unitPrice.firstUnit)} - ${formatNumber(unitPrice.lastUnit)}`}</td>
			<td style={{textAlign: "right"}}>{unitPrice.perUnitFormatted() + "/" + translate(DICTIONARY.MONTH.X) + " " +
			translate(DICTIONARY.PLUS_TAXES.X)}</td>
		</tr>
/*		switch (props.subscription.type) {
			case SUBSCRIPTION_TYPE.BUSINESS:
				title = translate(DICTIONARY.CUSTOMER_CREDITS.X)
				product = props.paralianProducts[PARALIAN_PRODUCT_NAME.ORGANIZATION_CUSTOMER_TOKEN]
				price = product.pricing.pricesById.byIndex(product.pricing.default[0])
				free = price.unitPrice[0].lastUnit
				message = <><p>
					{translate(DICTIONARY.PLEASE_REFER_TO.X)}
					<a href={"https://paralian.io"}>
						{translate(DICTIONARY.OUR_WEBSITE.X)}
					</a>
					{translate(DICTIONARY.FOR_PRICING_INFORMATION.X)}</p>
					<table style={{width: "100%"}}>
						<tr>
							<th>{translate(DICTIONARY.CREDITS.X)}</th>
							<th style={{textAlign: "right"}}>{translate(DICTIONARY.PRICE_PER_CREDIT.X)}</th>
						</tr>
						{price.unitPrice.map(up => tableRow(up))}
					</table>
				</>
				image = ancreGold
				break
			case SUBSCRIPTION_TYPE.MARINA:
				title = translate(DICTIONARY.CUSTOMER_CREDITS.X)
				product = props.paralianProducts[PARALIAN_PRODUCT_NAME.ORGANIZATION_CUSTOMER_TOKEN]
				price = product.pricing.pricesById.byIndex(product.pricing.default[0])
				free = price.unitPrice[0].lastUnit
				message = <><p>
					{translate(DICTIONARY.PLEASE_REFER_TO.X)}
					<a href={"https://paralian.io"}>
						{translate(DICTIONARY.OUR_WEBSITE.X)}
					</a>
					{translate(DICTIONARY.FOR_PRICING_INFORMATION.X)}</p>
					<table style={{width: "100%"}}>
						<tr>
							<th>{translate(DICTIONARY.CREDITS.X)}</th>
							<th style={{textAlign: "right"}}>{translate(DICTIONARY.PRICE_PER_CREDIT.X)}</th>
						</tr>
						{price.unitPrice.map(up => tableRow(up))}
					</table>
				</>
				image = ancreGold
				break
			case SUBSCRIPTION_TYPE.BROKER:
				title = translate(DICTIONARY.CUSTOMER_CREDITS.X)
				product = props.paralianProducts[PARALIAN_PRODUCT_NAME.ORGANIZATION_CUSTOMER_TOKEN]
				price = product.pricing.pricesById.byIndex(product.pricing.default[0])
				free = price.unitPrice[0].lastUnit
				message = <><p>
					{translate(DICTIONARY.PLEASE_REFER_TO.X)}
					<a href={"https://paralian.io"}>
						{translate(DICTIONARY.OUR_WEBSITE.X)}
					</a>
					{translate(DICTIONARY.FOR_PRICING_INFORMATION.X)}</p>
					<table style={{width: "100%"}}>
						<tr>
							<th>{translate(DICTIONARY.CREDITS.X)}</th>
							<th style={{textAlign: "right"}}>{translate(DICTIONARY.PRICE_PER_CREDIT.X)}</th>
						</tr>
						{price.unitPrice.map(up => tableRow(up))}
					</table>
				</>
				image = ancreGold
				break
			case SUBSCRIPTION_TYPE.PERSONAL:
				title = translate(DICTIONARY.PERSONAL_PREMIUM.X)
				product = props.paralianProducts[PARALIAN_PRODUCT_NAME.PERSONAL_PREMIUM]
				price = product.pricing.pricesById.byIndex(product.pricing.default[0])
				message = <p>{translate(DICTIONARY.PRICE_OF_PREMIUM_MEMBERSHIP_IS.X) + price.unitPrice[0].perUnitFormatted() +
					translate(DICTIONARY.PLUS_TAXES.X)}</p>
				image = ancreGoldRedDot
				break
			default:
				return <></>
		}*/
		title = translate(DICTIONARY.PERSONAL_PREMIUM.X)
		product = props.subscription.products[0]
		price = product.pricing.pricesById.byIndex(product.pricing.default[0])
		message = <p>{translate(DICTIONARY.PRICE_OF_PREMIUM_MEMBERSHIP_IS.X) + price.unitPrice[0].perUnitFormatted() +
		translate(DICTIONARY.PLUS_TAXES.X)}</p>
		image = ancreGoldRedDot
		return <Modal icon={ICONS.INFO_CIRCLE} background={"white"} padding={"0vh"}
			onClick={props.onClick} id={`${props.product}-pricing`}
			body={<div style={{padding: "2vh 0vh", color: "black", margin: "2vh"}}>
				<div style={{marginBottom: "2vh", display: "inline-flex", fontSize: "2.5vh"}}>
					<MobileImage image={new Image({path: image})} size={"5vh"} alt={"profile"} style={{margin: "auto auto"}}
						id={"paralian-product-pricing-modal-img"}/>
					<div style={{margin: "auto 2vh"}}>
						{title}
					</div>
				</div>
				<div style={{textAlign: "justify"}}>
					{message}
				</div>
			</div>}/>
	} catch (e) {
		console.log(e)
		return <></>
	}
}

export default MobileParalianProductPricing