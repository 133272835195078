import React from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {fadingMessage, translate} from "../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../MobileFontAwesome"
import Servicio from "../../../globals/classes/shared/Servicio"
import {EMPLOYEE_SERVICE_ROLE} from "../../../globals/constants/shared/enumerators"
/**
 *
 * @param {Object} props
 * @param {number|string} props.id
 * @param {Object} props.service
 * @param {string[]} props.service.role
 * @param {number} props.service.itemCategory
 * @param {Servicio[]} props.services
 */
const MobileEmployeeServiceAccessFields = props => {
	console.log("MobileEmployeeServiceAccessFields")
	try {
		const service_ = new Servicio(props.services.find(service__ => props.service.itemCategory === service__.id))
		console.log(props.service)
		const label = service_.labelsAsString
		// EXECUTIVE
		const executive = props.service.role.includes(EMPLOYEE_SERVICE_ROLE.EXECUTIVE) ?
			<MobileFontAwesome icon={ICONS.USER_TIE} style={{padding: "1.5vh"}} onClick={() => {
				fadingMessage(`executive-message`, translate(DICTIONARY.EXECUTIVE.X),
					translate(DICTIONARY.EMPLOYEE_EXECUTIVE_MESSAGE.X), "w3-pale-blue", ICONS.USER_TIE)
			}}/> : <></>
		// COORDINATOR
		const coordinator = props.service.role.includes(EMPLOYEE_SERVICE_ROLE.COORDINATOR) ?
			<MobileFontAwesome icon={ICONS.USER_HEADSET} style={{padding: "1.5vh"}} onClick={() => {
				fadingMessage(`coordinator-message`, translate(DICTIONARY.COORDINATOR.X),
					translate(DICTIONARY.EMPLOYEE_COORDINATOR_MESSAGE.X), "w3-pale-blue", ICONS.USER_HEADSET)
			}}/> : <></>
		// WORKER
		const worker = props.service.role.includes(EMPLOYEE_SERVICE_ROLE.WORKER) ?
			<MobileFontAwesome icon={ICONS.USER_ASTRONAUT} style={{padding: "1.5vh"}} onClick={() => {
				fadingMessage(`worker-message`, translate(DICTIONARY.WORKER.X),
					translate(DICTIONARY.EMPLOYEE_WORKER_MESSAGE.X),
					"w3-pale-blue", ICONS.USER_ASTRONAUT)
			}}/> : <></>
		// NONE
	const none = props.service.role.includes(EMPLOYEE_SERVICE_ROLE.NONE) ?
			<MobileFontAwesome icon={ICONS.BAN} style={{padding: "1.5vh"}} onClick={() => {
				fadingMessage(`no-access-message`,
					translate(DICTIONARY.NO_ACCESS.X), translate(DICTIONARY.EMPLOYEE_NO_ACCESS_MESSAGE.X),
					"w3-pale-blue", ICONS.BAN)
			}}/> : <></>
		return <div key={`${props.id}-${props.service.service}`} className={"paralian-theme-level-1"}
			style={{margin: "1vh 0vh", padding: "1vh", borderRadius: "1vh"}}>
			<div className={"paralian-theme-level-1-flipped"} style={{margin: "1vh", padding: "1vh", borderRadius: "1vh"}}>
				<b>{label}</b>
			</div>
			<div style={{fontSize: "5vh", margin: "2vh 0vh"}}>
				{none}
				{worker}
				{coordinator}
				{executive}
			</div>
		</div>
	} catch (e) {
		console.log(e)
		return <></>
	}
}

export default MobileEmployeeServiceAccessFields