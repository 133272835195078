import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {EMPLOYEE_SERVICE_ROLE} from "../../../globals/constants/shared/enumerators"
import {fadingMessage, translate} from "../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../MobileFontAwesome"
import MobileInputSlider from "../../shared/input/switch/MobileInputSlider"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {Employee} props.employee
 * @param {Servicio} props.service
 * @returns {JSX.Element}
 * @constructor
 */
const MobileServiceAccess = props => {
	// console.log("MobileServiceAccess")
	const [role, setRole] = useState(props.employee ?
		props.employee.services.find(s => s.itemCategory === props.service.id).role.split(",") :
		[EMPLOYEE_SERVICE_ROLE.WORKER])
	const [noneChecked, setNoneChecked] = useState(false)
	const [workerChecked, setWorkerChecked] = useState(false)
	const [coordinatorChecked, setCoordinatorChecked] = useState(false)
	const [executiveChecked, setExecutiveChecked] = useState(false)
	// console.log(role)
	useEffect(() => {
		const svc = props.employee ? props.employee.services.find(service => service.itemCategory === props.service.id) : null
		const role_ = svc ? svc.role.split(",") : [EMPLOYEE_SERVICE_ROLE.WORKER]
		setRole(role_)
	}, [props.employee, props.service.id])
	useEffect(() => {
		setNoneChecked(role.includes(EMPLOYEE_SERVICE_ROLE.NONE))
		setWorkerChecked(role.includes(EMPLOYEE_SERVICE_ROLE.WORKER))
		setCoordinatorChecked(role.includes(EMPLOYEE_SERVICE_ROLE.COORDINATOR))
		setExecutiveChecked(role.includes(EMPLOYEE_SERVICE_ROLE.EXECUTIVE))
	}, [role])
	const updateRole = _role => {
		// console.log("_role", _role)
		const rolePreviouslySelected = role.includes(_role)
		// console.log("rolePreviouslySelected", rolePreviouslySelected)
		if (_role === EMPLOYEE_SERVICE_ROLE.NONE) {
			// document.getElementById(`no-access-${props.id}`).click()
			if (rolePreviouslySelected) {
				document.getElementById(`service-access-${props.id}`).setAttribute("data-access",
					`${EMPLOYEE_SERVICE_ROLE.WORKER}`)
				setRole([EMPLOYEE_SERVICE_ROLE.WORKER])
			} else {
				document.getElementById(`service-access-${props.id}`).setAttribute("data-access",`${_role}`)
				setRole([_role])
			}
		}
		else if (_role === EMPLOYEE_SERVICE_ROLE.EXECUTIVE) {
			// document.getElementById(`executive-${props.id}`).click()
			if (rolePreviouslySelected) {
				document.getElementById(`service-access-${props.id}`).setAttribute("data-access",
					`${EMPLOYEE_SERVICE_ROLE.NONE}`)
				// document.getElementById(`no-access-${props.id}`).click()
				setRole([EMPLOYEE_SERVICE_ROLE.NONE])
			} else {
				document.getElementById(`service-access-${props.id}`).setAttribute("data-access",`${_role}`)
				setRole([_role])
			}
		}
		else if (_role === EMPLOYEE_SERVICE_ROLE.WORKER) {
			// document.getElementById(`worker-${props.id}`).click()
			if (rolePreviouslySelected) {
				if (role.length === 1) {
					document.getElementById(`service-access-${props.id}`).setAttribute("data-access",
						`${EMPLOYEE_SERVICE_ROLE.NONE}`)
					// document.getElementById(`no-access-${props.id}`).click()
					setRole([EMPLOYEE_SERVICE_ROLE.NONE])
				} else {
					const arr = [...role]
					const indexToRemove = arr.findIndex(item => item === EMPLOYEE_SERVICE_ROLE.WORKER)
					arr.splice(indexToRemove, 1)
					document.getElementById(`service-access-${props.id}`).setAttribute("data-access",
						`${arr}`)
					setRole([...arr])
				}
			} else {
				if (role.includes(EMPLOYEE_SERVICE_ROLE.NONE) || role.includes(EMPLOYEE_SERVICE_ROLE.EXECUTIVE)) {
					document.getElementById(`service-access-${props.id}`).setAttribute("data-access",
						`${_role}`)
					setRole([_role])
				} else {
					document.getElementById(`service-access-${props.id}`).setAttribute("data-access",
						`${[...role, _role]}`)
					setRole([...role, _role])
				}
			}
		}
		// COORDINATOR
		else {
			// document.getElementById(`coordinator-${props.id}`).click()
			if (rolePreviouslySelected) {
				if (role.length === 1) {
					document.getElementById(`service-access-${props.id}`).setAttribute("data-access",
						`${EMPLOYEE_SERVICE_ROLE.NONE}`)
					// document.getElementById(`no-access-${props.id}`).click()
					setRole([EMPLOYEE_SERVICE_ROLE.NONE])
				} else {
					const arr = [...role]
					const indexToRemove = arr.findIndex(item => item === EMPLOYEE_SERVICE_ROLE.COORDINATOR)
					arr.splice(indexToRemove, 1)
					document.getElementById(`service-access-${props.id}`).setAttribute("data-access",
						`${arr}`)
					setRole([...arr])
				}
			} else {
				if (role.includes(EMPLOYEE_SERVICE_ROLE.NONE) || role.includes(EMPLOYEE_SERVICE_ROLE.EXECUTIVE)) {
					document.getElementById(`service-access-${props.id}`).setAttribute("data-access",
						`${_role}`)
					setRole([_role])
				} else {
					document.getElementById(`service-access-${props.id}`).setAttribute("data-access",
						`${[...role, _role]}`)
					setRole([...role, _role])
				}
			}
		}
	}
	const translatedLabels = props.service.labelsAsString
	return <div className={props.className || "paralian-theme-level-1-flipped"}
		style={{padding: "1vh", margin: "1vh 0vh", borderRadius: "1vh"}}>
		<div id={`service-access-${props.id}`} className={props.className === "paralian-theme-level-1" ?
			"paralian-theme-level-1-flipped" : "paralian-theme-level-4"} data-id={props.service.id}
				 style={{margin: "1vh", padding: "1vh", textAlign: "center", borderRadius: "1vh"}}
				 data-access={role}>{translatedLabels}</div>
		<div style={{display: "flex", margin: "0.5vh"}}>
			<MobileFontAwesome icon={ICONS.INFO_CIRCLE} style={{fontSize: "3vh", marginRight: "1vh"}}
				onClick={() => fadingMessage(`worker-message`, translate(DICTIONARY.WORKER.X),
				translate(DICTIONARY.EMPLOYEE_WORKER_MESSAGE.X),
				"w3-pale-blue", ICONS.USER_ASTRONAUT)}/>
			<div style={{width: "100%"}} className={"w3-display-container"}>
				<MobileInputSlider id={`worker-${props.id}`} label={translate(DICTIONARY.WORKER.X)}
					padding={"0.25vh 0vh 0.25vh 2vh"} height={"3vh"} width={"8vh"} defaultChecked={workerChecked}
					className={"w3-display-left"} form={"form"} onChange={() => updateRole(EMPLOYEE_SERVICE_ROLE.WORKER)}/>
			</div>
		</div>
		<div style={{display: "flex", margin: "0.5vh"}}>
			<MobileFontAwesome icon={ICONS.INFO_CIRCLE} style={{fontSize: "3vh", marginRight: "1vh"}}
				onClick={() => fadingMessage(`coordinator-message`, translate(DICTIONARY.COORDINATOR.X),
				translate(DICTIONARY.EMPLOYEE_COORDINATOR_MESSAGE.X), "w3-pale-blue", ICONS.USER_HEADSET)}/>
			<div style={{width: "100%"}} className={"w3-display-container"}>
				<MobileInputSlider id={`coordinator-${props.id}`} label={translate(DICTIONARY.COORDINATOR.X)} form={"form"}
					height={"3vh"} width={"8vh"} defaultChecked={coordinatorChecked} className={"w3-display-left"}
					padding={"0.25vh 0vh 0.25vh 2vh"} onChange={() => updateRole(EMPLOYEE_SERVICE_ROLE.COORDINATOR)}/>
			</div>
		</div>
		<div style={{display: "flex", margin: "0.5vh"}}>
			<MobileFontAwesome icon={ICONS.INFO_CIRCLE} style={{fontSize: "3vh", marginRight: "1vh"}}
				onClick={() => fadingMessage(`executive-message`, translate(DICTIONARY.EXECUTIVE.X),
				translate(DICTIONARY.EMPLOYEE_EXECUTIVE_MESSAGE.X), "w3-pale-blue", ICONS.USER_TIE)}/>
			<div style={{width: "100%"}} className={"w3-display-container"}>
				<MobileInputSlider id={`executive-${props.id}`} label={translate(DICTIONARY.EXECUTIVE.X)} form={"form"}
					height={"3vh"} width={"8vh"} defaultChecked={executiveChecked} className={"w3-display-left"}
					padding={"0.25vh 0vh 0.25vh 2vh"} onChange={() => updateRole(EMPLOYEE_SERVICE_ROLE.EXECUTIVE)}/>
			</div>
		</div>
		<div style={{display: "flex", margin: "0.5vh"}}>
			<MobileFontAwesome icon={ICONS.INFO_CIRCLE} style={{fontSize: "3vh", marginRight: "1vh"}}
				onClick={() => fadingMessage(`no-access-message`,
				translate(DICTIONARY.NO_ACCESS.X), translate(DICTIONARY.EMPLOYEE_NO_ACCESS_MESSAGE.X),
				"w3-pale-blue", ICONS.BAN)}/>
			<div style={{width: "100%"}} className={"w3-display-container"}>
				{/*<div className={"w3-display-left"} style={{height: "100%", width: "100%", zIndex: "10"}}>{""}</div>*/}
				<MobileInputSlider id={`no-access-${props.id}`} label={translate(DICTIONARY.NO_ACCESS.X)}
					padding={"0.25vh 0vh 0.25vh 2vh"} onChange={() => updateRole(EMPLOYEE_SERVICE_ROLE.NONE)}
					height={"3vh"} width={"8vh"} defaultChecked={noneChecked} className={"w3-display-left"} form={"form"}/>
			</div>
		</div>
	</div>
}

export default MobileServiceAccess