const getEmployeeInput = params => {
	// console.log(params.id)
	let name = null, title = null, email = null, status = null, role = null
	try {
		name = document.getElementById(`${params.id}-employee-name-input`).value
	} catch (e) {
		console.log(e)
	}
	try {
		title = document.getElementById(`${params.id}-employee-title-input`).value
	} catch (e) {
		console.log(e)
	}
	try {
		email = document.getElementById(`${params.id}-employee-email-input`).value
	} catch (e) {
		console.log(e)
	}
	try {
		status = document.getElementById(`active-button-${params.id}`).getAttribute("data-active")
	} catch (e) {
		console.log(e)
	}

	// `${props.id}-employee-service-${service.id}-access`
	// service-access-add-employee-service-1001-access
	const servicesInput = params.services.map(s => {
		// console.log(`service-access-${params.id}-employee-service-${s.id}-access`)
		const element = document.getElementById(`service-access-${params.id}-employee-service-${s.id}-access`)
		return {
			id: s.id,
			role: element.getAttribute("data-access")
		}
	})
	return {name: name, email: email, title: title, services: servicesInput, status: status, id: params.id}
}

export default getEmployeeInput