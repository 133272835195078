import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {getFromStorage, translate} from "../../../../globals/functions/client/localFunctions"
import RadioButton from "./RadioButton"

const SurfaceProtection = props => {
	const [selected, setSelected] = useState(null)
	useEffect(() => {
		const session = getFromStorage("order", "session")
		if (session.servicesExterior.some(v => v.id === 1006 || v.id === 1004)) {
			setSelected("wax")
		} else if (session.servicesExterior.some(v => v.id === 1100)) {
			setSelected("sealant")
		} else if (session.servicesExterior.some(v => v.id === 1012)) {
			setSelected("ceramic-1")
		} else if (session.servicesExterior.some(v => v.id === 1102)) {
			setSelected("ceramic-2")
		} else if (session.servicesExterior.some(v => v.id === 1103)) {
			setSelected("ceramic-3")
		}
	}, []) //1006, 1100, 1012, 1102, 1103
	const onClick = id => {
		setSelected(id)
		if (props.onClick) {
			console.log("SurfaceProtection onClick", id)
			props.onClick(id)
		}
	}
	console.log("SurfaceProtection selected", selected)
	const asterisk = <sup>{"*"}</sup>
	return <fieldset style={{margin: "1vh 0vh"}}>
		<legend style={{padding: "0vh 1vh", fontSize: "3vh"}}>{translate(DICTIONARY.DESIRED_SURFACE_PROTECTION.X)}</legend>
		<RadioButton id={"wax"} title={translate(DICTIONARY.CARNAUBA_WAX.X)}
			subtitle={translate(DICTIONARY.WAX_DURATION.X)} onClick={onClick}
			selected={selected === "wax"} name={"surfaceState"}/>
		<RadioButton id={"sealant"} title={translate(DICTIONARY.SYNTHETIC_SEALANT.X)}
			subtitle={translate(DICTIONARY.SEALANT_DURATION.X)} onClick={onClick}
			selected={selected === "sealant"} defaultChecked={true} name={"surfaceState"}/>
		<RadioButton id={"ceramic-1"} title={translate(DICTIONARY.CERAMIC_1.X)}
			subtitle={translate(DICTIONARY.CERAMIC_1_DURATION.X)} onClick={onClick}
			selected={selected === "ceramic-1"} name={"surfaceState"}/>
		<RadioButton id={"ceramic-2"} title={translate(DICTIONARY.CERAMIC_2.X)}
			subtitle={translate(DICTIONARY.CERAMIC_2_DURATION.X)} onClick={onClick}
			selected={selected === "ceramic-2"} name={"surfaceState"}/>
		<RadioButton id={"ceramic-3"} title={translate(DICTIONARY.CERAMIC_3.X)}
			subtitle={translate(DICTIONARY.CERAMIC_3_DURATION.X)} onClick={onClick}
			selected={selected === "ceramic-3"} name={"surfaceState"}/>
		<div style={{padding: "1vh 0vh"}}>{<i>{asterisk}{translate(DICTIONARY.SURFACE_PROTECTION_DURATION_DISCLAIMER.X)}</i>}</div>
	</fieldset>
}

export default SurfaceProtection