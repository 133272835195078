import React, {useEffect} from "react"
import {DICTIONARY, ICONS} from "../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../globals/constants/shared/enumerators"
import {fadingMessage, translate} from "../../globals/functions/client/localFunctions"
import {deleteNotification} from "../../globals/functions/client/serverFunctions"
import MobileImage from "./account/MobileImage"
import MobileFontAwesome from "./MobileFontAwesome"
import MobileButtonGeneric from "../shared/input/buttons/MobileButtonGeneric"
import Image from "../../globals/classes/shared/Image"

const MobileNotifications = props => {
	useEffect(() => {
		props.refreshNotifications()
	}, [])
/*	useEffect(() => {
		checkIfPushEnabled.then(response => setPushEnabled(response))
	}, [])*/
	// console.log(props.notifications)
	// newMessage(payload.notification.tag, payload.notification.title, payload.notification.body, "w3-pale-blue",
	// 				ICONS.INFO_CIRCLE)
	const items = props.notifications.length > 0 ? props.notifications.map(notification => {
		return <div key={`notification-${notification.webpush.notification.tag}`} style={{borderRadius: "1vh"}}
			id={`notification-${notification.webpush.notification.tag}`} className={"paralian-mobile-info-card"}>
						<div style={{display: "inline-flex", maxWidth: "36vh", width: "100%"}}>
				<MobileImage image={new Image({path: notification.webpush.notification.icon})} size={"6vh"} alt={"logo"}
					style={{margin: "auto 1vh"}} id={`notification-${notification.webpush.notification.tag}-img`}/>
				<div style={{padding: "2vh", textAlign: "right", width: "100%"}}>
					<div style={{fontSize: "2vh"}}>
						{notification.notification.title}
					</div>
					{
						notification.notification.timestamp ?
							<div style={{fontSize: "1.5vh", opacity: "0.5"}}>
								{new Date(notification.notification.timestamp).toLocaleString()}
							</div> : <></>
					}
				</div>
			</div>
			<div style={{fontSize: "2vh", opacity: "0.5", padding: "1vh 0vh"}}>
			<i>{notification.notification.body}</i>
			</div>
			<div style={{margin: "1vh 0vh"}}>
				<MobileButtonGeneric id={"delete"} type={"button"} left={{icon: ICONS.BAN}}
					text={{style: {paddingLeft: "2vh"}, content: translate(DICTIONARY.DELETE.X)}}
					className={"paralian-theme-level-1-flipped"}
					padding={"1vh"} style={{width: "100%", borderRadius: "1vh"}} onClick={async () => {
						const response = await deleteNotification(notification.webpush.notification.tag)
						if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
							props.refreshNotifications()
						}
						}}/>
			</div>
		</div>
	}) :
		<div style={{paddingTop: "10vh"}}>
			<div>
				<MobileFontAwesome icon={ICONS.BELL_SLASH} style={{fontSize: "20vh"}}
					label={translate(DICTIONARY.NO_NOTIFICATIONS.X)} onClick={() => fadingMessage("no-orders",
						translate(DICTIONARY.NO_NOTIFICATIONS.X), "", "w3-pale-blue",	ICONS.BELL_SLASH)}/>
			</div>
			<div style={{fontSize: "3vh"}}>
				{translate(DICTIONARY.NO_NOTIFICATIONS.X)}
			</div>
		</div>
	return <>
		<div className={"w3-display-middle w3-animate-opacity paralian-scroll paralian-hide-scroll-ms " +
			"paralian-hide-scroll-chrome"} style={{width: "100%", height: "83vh"}}>
			<div className="sticky paralian-theme-level-1" style={{zIndex: "3", padding: "2vh 0vh", display: "flex",
				alignContent: "center", alignItems: "center", height: "6vh", fontSize: "2.5vh"}}>
				<div style={{margin: "1vh auto", cursor: "pointer"}} className={"w3-ripple"}
						 onClick={() => props.refreshNotifications()}>
					<MobileFontAwesome label={translate(DICTIONARY.REFRESH.X)} icon={ICONS.SYNC} style={{padding: "0vh 2vh"}}/>
					<span style={{fontSize: "2vh"}}>{translate(DICTIONARY.REFRESH.X)}</span>
				</div>
			</div>
			{items}
		</div>
	</>
}

export default MobileNotifications