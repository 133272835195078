const DOCUMENT_TYPE = Object.freeze({BONDS: "BONDS", CERTIFICATES: "CERTIFICATES",
	INSURANCE: "INSURANCE",	VESSEL_TITLES: "VESSEL_TITLES", VESSEL_REGISTRATIONS: "VESSEL_REGISTRATIONS",
	VESSEL_DOCUMENTATIONS: "VESSEL_DOCUMENTATIONS", ORGANIZATION_REGISTRATIONS: "ORGANIZATION_REGISTRATIONS",
	CONTRACTS: "CONTRACTS", PUBLIC: "PUBLIC", WARRANTIES: "WARRANTIES", UPKEEP: "UPKEEP", MISCELLANEOUS: "MISCELLANEOUS"})
const DOCUMENT_CONTAINER = Object.freeze({ACCOUNTS: "ACCOUNTS", VESSELS: "VESSELS", ORGANIZATIONS: "ORGANIZATIONS"}) // TODO: rename to DATA_CONTAINER
const DOCUMENT_TYPE_MINIMAL = Object.freeze({UPKEEP: "UPKEEP", BONDS: "BONDS", CERTIFICATES: "CERTIFICATES",
	INSURANCE: "INSURANCE",	TITLES: "TITLES", REGISTRATIONS: "REGISTRATIONS",	DOCUMENTATIONS: "DOCUMENTATIONS",
	CONTRACTS: "CONTRACTS", PUBLIC: "PUBLIC", WARRANTIES: "WARRANTIES", MISCELLANEOUS: "MISCELLANEOUS"})
const VERIFICATION_STATE = Object.freeze({VERIFIED: "VERIFIED", PENDING: "PENDING", DECLINED: "DECLINED", PROVISIONAL: "PROVISIONAL"})
const ACCOUNT_STATE = Object.freeze({ACTIVE: "ACTIVE", DISABLED: "DISABLED", CLOSED: "CLOSED", LOCKED: "LOCKED",
	NEW: "NEW", INVITED: "INVITED", INCOMPLETE: "INCOMPLETE"})
const ACCOUNT_TYPE = Object.freeze({PERSONAL: "PERSONAL",	MARINA_ADMIN: "MARINA_ADMIN",
	MARINA_EMPLOYEE: "MARINA_EMPLOYEE",	BUSINESS_ADMIN: "BUSINESS_ADMIN", BUSINESS_EMPLOYEE: "BUSINESS_EMPLOYEE"})
const VERIFICATION_TYPE = Object.freeze({
	PASSWORD_UPDATE: "PASSWORD_UPDATE", PASSWORD_RESET: "PASSWORD_RESET", PRIMARY_EMAIL:"PRIMARY_EMAIL",
	PRIMARY_PHONE: "PRIMARY_PHONE", SECONDARY_EMAIL: "SECONDARY_EMAIL",
	VESSEL_OWNERSHIP_CONFIRMATION: "VESSEL_OWNERSHIP_CONFIRMATION",
	SECONDARY_PHONE: "SECONDARY_PHONE", PARENT_ACCOUNT: "PARENT_ACCOUNT", PARENT_BUSINESS: "PARENT_BUSINESS",
	NEW_USER_INVITATION: "NEW_USER_INVITATION", LOGIN_EMAIL: "LOGIN_EMAIL", LOGIN_PHONE: "LOGIN_PHONE"})
const ORGANIZATION_TYPE = Object.freeze({INDIVIDUAL: "INDIVIDUAL", COMPANY: "COMPANY",	NON_PROFIT: "NON_PROFIT",
	GOVERNMENT_ENTITY: "GOVERNMENT_ENTITY"})
const ORGANIZATION_CATEGORY = Object.freeze({MARINA: "MARINA", BUSINESS: "BUSINESS",
	VESSEL_REGISTRATION_AUTHORITY: "VESSEL_REGISTRATION_AUTHORITY",
	VESSEL_TITLING_AUTHORITY: "VESSEL_TITLING_AUTHORITY",
	VESSEL_DOCUMENTATION_AUTHORITY: "VESSEL_DOCUMENTATION_AUTHORITY"})
const ORGANIZATION_STRUCTURE = Object.freeze({
	SOLE_PROPRIETORSHIP: "SOLE_PROPRIETORSHIP",
	SINGLE_MEMBER_LLC: "SINGLE_MEMBER_LLC",
	MULTI_MEMBER_LLC: "MULTI_MEMBER_LLC",
	LIMITED_LIABILITY_PARTNERSHIP: "LIMITED_LIABILITY_PARTNERSHIP",
	PRIVATE_COMPANY: "PRIVATE_COMPANY",
	PRIVATE_PARTNERSHIP: "PRIVATE_PARTNERSHIP",
	PRIVATE_CORPORATION: "PRIVATE_CORPORATION",
	PUBLIC_PARTNERSHIP: "PUBLIC_PARTNERSHIP",
	PUBLIC_COMPANY: "PUBLIC_COMPANY",
	PUBLIC_CORPORATION: "PUBLIC_CORPORATION",
	UNINCORPORATED_ASSOCIATION: "UNINCORPORATED_ASSOCIATION",
	INCORPORATED_NON_PROFIT: "INCORPORATED_NON_PROFIT",
	UNINCORPORATED_NON_PROFIT: "UNINCORPORATED_NON_PROFIT",
	TAX_EXEMPT_GOVERNMENT_INSTRUMENTALITY: "TAX_EXEMPT_GOVERNMENT_INSTRUMENTALITY",
	GOVERNMENTAL_UNIT: "GOVERNMENTAL_UNIT",
	GOVERNMENT_INSTRUMENTALITY: "GOVERNMENT_INSTRUMENTALITY"})
const VESSEL_TYPE = Object.freeze({CENTER_CONSOLE: "CENTER_CONSOLE", EXPRESS: "EXPRESS",	CRUISER: "CRUISER",
	TRAWLER: "TRAWLER"})
const FUEL = Object.freeze({DIESEL: "DIESEL", PETROL_87: "PETROL_87", PETROL_89: "PETROL_89",
	PETROL_91: "PETROL_91", PETROL_93: "PETROL_93", KEROSENE: "KEROSENE", NATURAL_GAS: "NATURAL_GAS",
	FLEX_FUEL: "FLEX_FUEL", LIQUEFIED_PROPANE_GAS: "LIQUEFIED_PROPANE_GAS", ELECTRIC: "ELECTRIC"})
const FLUID = Object.freeze({POTABLE_WATER: "POTABLE_WATER", FRESH_WATER: "FRESH_WATER",	WASTE: "WASTE",
	...FUEL})
const ENGINE_CLASS = Object.freeze({OUTBOARD: "OUTBOARD", INBOARD: "INBOARD", STERN_DRIVE: "STERN_DRIVE", JET_DRIVE: "JET_DRIVE"})
const ENGINE_ROTATION = Object.freeze({STANDARD: "STANDARD", COUNTER_ROTATING: "COUNTER_ROTATING"})
const PARALIAN_LANGUAGE = Object.freeze({EN: "en", ES: "es", FR: "fr"})
const UNIT_SYSTEM = Object.freeze({SI: "SI", US: "US"})
const DAY_DATE = Object.freeze({DAY: "DAY", DATE: "DATE"})
const RECURRENCE = Object.freeze({DAILY: "DAILY", WEEKLY: "WEEKLY", MONTHLY: "MONTHLY", YEARLY: "YEARLY", ONCE: "ONCE"})
const MONTH = Object.freeze({
	JANUARY: "JANUARY", FEBRUARY: "FEBRUARY", MARCH: "MARCH", APRIL: "APRIL", MAY: "MAY",
	JUNE: "JUNE", JULY: "JULY", AUGUST: "AUGUST", SEPTEMBER: "SEPTEMBER", OCTOBER: "OCTOBER", NOVEMBER: "NOVEMBER",
	DECEMBER: "DECEMBER"
})
const WEEKDAY = Object.freeze({
	SUNDAY: "SUNDAY", MONDAY: "MONDAY", TUESDAY: "TUESDAY", WEDNESDAY: "WEDNESDAY", THURSDAY: "THURSDAY",
	FRIDAY: "FRIDAY", SATURDAY: "SATURDAY"
})
const ORDINAL = Object.freeze({FIRST: "FIRST", SECOND: "SECOND", THIRD: "THIRD", LAST: "LAST"})
const ORDER_TYPE = Object.freeze({
	VESSEL_STORAGE_UNIT_RESERVATION: "vessel_storage_units_reservation_orders",
	VESSEL_TRANSPORT: "vessel_transport_orders"
})
const ORDER_BUTTON = Object.freeze({
	ACCEPT: "ACCEPT",
	ADJUST_PROPOSE: "ADJUST_PROPOSE",
	ADJUST_RESPOND: "ADJUST_RESPOND",
	ADJUST_RESPOND_ACCEPT: "ADJUST_RESPOND_ACCEPT",
	ADJUST_RESPOND_REJECT: "ADJUST_RESPOND_REJECT",
	ASSIGN: "ASSIGN",
	BLOCK: "BLOCK",
	BLOCK_ACCOUNT: "BLOCK_ACCOUNT",
	BLOCK_VESSEL: "BLOCK_VESSEL",
	BLOCK_ORGANIZATION: "BLOCK_ORGANIZATION",
	CANCEL: "CANCEL",
	COMPLETE: "COMPLETE",
	CONTACT: "CONTACT",
	CONFIRM: "CONFIRM",
	DECLINE: "DECLINE",
	DISPUTE: "DISPUTE",
	FAVORITE: "FAVORITE",
	INCOMPLETE: "INCOMPLETE",
	INCOMPLETE_AND_REQUEST_PARTIAL_PAYMENT: "INCOMPLETE_AND_REQUEST_PARTIAL_PAYMENT",
	INCOMPLETE_AND_CANCEL: "INCOMPLETE_AND_CANCEL",
	INCOMPLETE_AND_REQUEST_FULL_PAYMENT: "INCOMPLETE_AND_REQUEST_FULL_PAYMENT",
	NONE: "NONE",
	PAY: "PAY",
	PAY_DIFFERENCE: "PAY_DIFFERENCE",
	RATE: "RATE",
	REFUND: "REFUND",
	REJECT: "REJECT",
	REPEAT: "REPEAT",
	SCHEDULE: "SCHEDULE",
	START: "START",
	MAKE_RECURRING: "MAKE_RECURRING",
	CANCEL_RECURRENCE: "CANCEL_RECURRENCE",
	EDIT_RECURRENCE: "EDIT_RECURRENCE",
	CHANGE_PAYMENT_METHOD: "CHANGE_PAYMENT_METHOD",
	MARK_PAID: "MARK_PAID",
	MARK_UNPAID: "MARK_UNPAID"
})
const ORDER_STATE = Object.freeze({
	ABORTED: "ABORTED",
	ACCEPTED: "ACCEPTED",
	COMPLETED: "COMPLETED",
	CONFIRM: "CONFIRM",
	ENTERED: "ENTERED",
	INCOMPLETE: "INCOMPLETE",
	INCOMPLETE_CUSTOMER: "INCOMPLETE_CUSTOMER",
	NEW: "NEW",
	STARTED: "STARTED",
	SUPERSEDED: "SUPERSEDED"
})
const ORDER_RECURRENCE_STATE = Object.freeze({
	ACTIVE: "ACTIVE",
	ANY: "ANY",
	CANCELLED: "CANCELLED",
	NONE: "NONE",
	PAUSED: "PAUSED",
	PENDING: "PENDING",
	RELEASED: "RELEASED",
	SUPERSEDED: "SUPERSEDED"
})
const ORDER_CHARGE_STATE = Object.freeze({
	CAPTURE_FAILED: "CAPTURE_FAILED",
	CAPTURED: "CAPTURED",
	CHARGE_METER_FAILED: "CHARGE_METER_FAILED",
	CHARGE_METERED: "CHARGE_METERED",
	HELD: "HELD",
	HOLD_FAILED: "HOLD_FAILED",
	NONE: "NONE",
	REFUNDED: "REFUNDED",
	RELEASED: "RELEASED",
	PAID: "PAID",
	UNPAID: "UNPAID",
	METER_UNUSED: "METER_UNUSED",
	METER_PENDING: "METER_PENDING",
	CANCELLED: "CANCELLED",
	CANCELLED_PAID: "CANCELLED_PAID",
	CANCELLED_UNPAID: "CANCELLED_UNPAID",
	INVOICE_PAYMENT_PENDING: "INVOICE_PAYMENT_PENDING"
})
const REQUEST_STATUS = Object.freeze({APPROVED: 'APPROVED', CANCELLED: 'CANCELLED', DENIED: "DENIED",
	INCOMPLETE: 'INCOMPLETE', SCHEDULED: 'SCHEDULED', PROCESSING: 'PROCESSING',
	DISPUTED: 'DISPUTED', PROVISIONAL: 'PROVISIONAL'})
const ADDRESS_TYPE = Object.freeze({PHYSICAL: "PHYSICAL", SUPPORT: "SUPPORT",
	BILLING: "BILLING",	PRIMARY: "PRIMARY", MAILING: "MAILING"})
const IMAGE_CATEGORY = Object.freeze({ACCOUNT_PROFILE: "ACCOUNT_PROFILE", VESSEL_PROFILE: "VESSEL_PROFILE",
	ORGANIZATION_ICON: "ORGANIZATION_ICON", ORGANIZATION_LOGO: "ORGANIZATION_LOGO", ORGANIZATION_IMAGE: "ORGANIZATION_IMAGE"})
const SERVER_RESPONSE_STATE = Object.freeze({SUCCESS: "SUCCESS", FAIL: "FAIL", BAD: "BAD"})
const TARGET_TYPE = Object.freeze({ACCOUNT: "ACCOUNT", SOURCE_ACCOUNT_VESSEL: "SOURCE_ACCOUNT_VESSEL",
	SOURCE_ACCOUNT_DOCUMENT: "SOURCE_ACCOUNT_DOCUMENT", SOURCE_ACCOUNT_IMAGE: "ACCOUNT_IMAGE",
	SOURCE_ACCOUNT_ADDRESS: "SOURCE_ACCOUNT_ADDRESS", ACCOUNT_VESSEL: "ACCOUNT_VESSEL",
	ACCOUNT_DOCUMENT: "ACCOUNT_DOCUMENT", ACCOUNT_IMAGE: "ACCOUNT_IMAGE",
	ACCOUNT_ADDRESS: "ACCOUNT_ADDRESS", ORGANIZATION: "ORGANIZATION", SOURCE_ORGANIZATION_VESSEL: "ORGANIZATION_VESSEL",
	SOURCE_ORGANIZATION_DOCUMENT: "ORGANIZATION_DOCUMENT", SOURCE_ORGANIZATION_IMAGE: "ORGANIZATION_IMAGE",
	SOURCE_ORGANIZATION_ADDRESS: "ACCOUNT_ADDRESS", ORGANIZATION_VESSEL: "ORGANIZATION_VESSEL",
	ORGANIZATION_DOCUMENT: "ORGANIZATION_DOCUMENT", ORGANIZATION_IMAGE: "ORGANIZATION_IMAGE",
	ORGANIZATION_ADDRESS: "ACCOUNT_ADDRESS", VESSEL: "VESSEL", VESSEL_DOCUMENT: "VESSEL_DOCUMENT",
	VESSEL_IMAGE: "VESSEL_IMAGE",	DOCUMENT: "DOCUMENT", IMAGE: "IMAGE", ADDRESS: "ADDRESS", NONE: "NONE"})
const LOGIN_TYPE = Object.freeze({ID: "ID", USERNAME: "USERNAME",	LOGIN_EMAIL: "LOGIN_EMAIL",
	LOGIN_PHONE: "LOGIN_PHONE"})
const CONTACT_INFO_TYPE = Object.freeze({PRIMARY_EMAIL: "PRIMARY_EMAIL", PRIMARY_PHONE: "PRIMARY_PHONE",
	SECONDARY_EMAIL: "SECONDARY_EMAIL",	SECONDARY_PHONE: "SECONDARY_PHONE"})
const PHYSICAL_ACCESS = Object.freeze({MOVE: "MOVE", LEAVE: "LEAVE", BOARD: "BOARD",	SERVICE: "SERVICE"})
const ORDER_ACCESS = Object.freeze({VIEW: "VIEW", PLACE: "PLACE", ASSIGN: "ASSIGN",	REJECT: "REJECT", ACCEPT: "ACCEPT", CANCEL: "CANCEL"})
const STANDARD_ACCESS = Object.freeze({VIEW: "VIEW", EDIT: "EDIT", INSERT: "INSERT", DELETE: "DELETE",
	SUGGEST_UPDATE: "SUGGEST_UPDATE", REPORT_BAD: "REPORT_BAD", SET_TRUE: "SET_TRUE", SET_FALSE: "SET_FALSE", CHILD: "CHILD"})
const EMPLOYEE_SERVICE_ROLE = Object.freeze({NONE: "NONE", ADMIN: "ADMIN", WORKER: "WORKER",	COORDINATOR: "COORDINATOR", EXECUTIVE: "EXECUTIVE"})
const COUNTRY_DIVISION = Object.freeze({STATE: "STATE", TERRITORY: "TERRITORY", REGION: "REGION",
	COLLECTIVITY: "COLLECTIVITY", PROVINCE: "PROVINCE", DEPENDENCY: "DEPENDENCY", PUBLIC_BODY: "PUBLIC_BODY", COUNTRY: "COUNTRY"})
const ID_TYPE = Object.freeze({VESSEL: "VESSEL", BUSINESS: "BUSINESS", MARINA: "MARINA",	ACCOUNT: "ACCOUNT",
	ORGANIZATION: "ORGANIZATION"})
const ACCEPT_REJECT = Object.freeze({ACCEPT: "ACCEPT", REJECT: "REJECT"})
const UPKEEP_CATEGORY = Object.freeze({MAINTENANCE: "MAINTENANCE", REPAIR: "REPAIR", IMPROVEMENT: "IMPROVEMENT", OTHER: "OTHER"})
const VESSEL_SYSTEM = Object.freeze({HULL: "HULL", ELECTRICAL: "ELECTRICAL", HVAC: "HVAC", PLUMBING: "PLUMBING", PROPULSION: "PROPULSION",
	NAVIGATION: "NAVIGATION", COMMUNICATIONS: "COMMUNICATIONS", SAFETY: "SAFETY", FUEL: "FUEL", STEERING: "STEERING", RIGGING: "RIGGING",
	SANITATION: "SANITATION", ENTERTAINMENT: "ENTERTAINMENT", LIGHTING: "LIGHTING", SECURITY: "SECURITY", OTHER: "OTHER"})
const PARALIAN_PRODUCT_NAME = Object.freeze({PERSONAL_PREMIUM: "PERSONAL_PREMIUM",
	PROVIDER_SUBSCRIPTION: "PROVIDER_SUBSCRIPTION", MARINA_SUBSCRIPTION: "MARINA_SUBSCRIPTION",
	ORDER_IGNORE_PENALTY: "ORDER_IGNORE_PENALTY"})
const SUBSCRIPTION_TYPE = Object.freeze({BUSINESS: "BUSINESS", MARINA: "MARINA", BROKER: "BROKER", PERSONAL: "PERSONAL"})
const SUBSCRIPTION_STATUS = Object.freeze({INCOMPLETE: "incomplete", ACTIVE: "active"})
const SUBSCRIPTION_FREQUENCY = Object.freeze({MONTHLY: "MONTHLY", YEARLY: "YEARLY", ONCE: "ONCE"})
const SUBSCRIPTION_INTERVAL = Object.freeze({YEAR: "YEAR", MONTH: "MONTH", ONE_TIME: "ONE_TIME"})
const SUBSCRIPTION_NAME = Object.freeze({PROVIDER_SUBSCRIPTION: "PROVIDER_SUBSCRIPTION",
	MARINA_SUBSCRIPTION: "MARINA_SUBSCRIPTION", BROKER_SUBSCRIPTION: "BROKER_SUBSCRIPTION",
	PERSONAL_SUBSCRIPTION: "PERSONAL_SUBSCRIPTION"})
const PRICING_MODEL = Object.freeze({STANDARD: "STANDARD", PACKAGE: "PACKAGE", GRADUATED: "GRADUATED", VOLUME: "VOLUME"})
const TERMS_OF_SERVICE = Object.freeze({TERMS_OF_USE: "TERMS_OF_USE", PRIVACY_POLICY: "PRIVACY_POLICY",
	COOKIE_POLICY: "COOKIE_POLICY", DISCLAIMERS: "DISCLAIMERS", VESSEL_OWNERSHIP_GRANTOR: "VESSEL_OWNERSHIP_GRANTOR",
	VESSEL_OWNERSHIP_VERIFIER: "VESSEL_OWNERSHIP_VERIFIER", SUBSCRIPTION_TERMS_OF_SALE: "SUBSCRIPTION_TERMS_OF_SALE"})
const PRICING_STRUCTURE_UNITS = Object.freeze({METER: 'METER', SQUARE_METER: 'SQUARE_METER', HOUR: "HOUR",
	CUBIC_METER: 'CUBIC_METER', LITER: 'LITER', KILOGRAM: 'KILOGRAM', KILOWATT: 'KILOWATT', UNIT: 'UNIT'})
const PROVIDER_SUBSCRIPTION_LEVEL = Object.freeze({TRIAL_SUBSCRIPTION: "TRIAL_SUBSCRIPTION",
	STARTUP_SUBSCRIPTION: "STARTUP_SUBSCRIPTION", BUSINESS_SUBSCRIPTION: "BUSINESS_SUBSCRIPTION"})
const MARINA_SUBSCRIPTION_LEVEL = Object.freeze({TRIAL_SUBSCRIPTION: "TRIAL_SUBSCRIPTION",
	STANDARD_SUBSCRIPTION: "STANDARD_SUBSCRIPTION", PARTNER_SUBSCRIPTION: "PARTNER_SUBSCRIPTION"})
const PERSONAL_SUBSCRIPTION_LEVEL = Object.freeze({BROKER_SUBSCRIPTION: "BROKER_SUBSCRIPTION"})
const BROKER_SUBSCRIPTION_LEVEL = Object.freeze({PERSONAL_PREMIUM: "PERSONAL_PREMIUM"})
const VESSEL_IDENTIFIER = Object.freeze({HIN: "HIN", REGISTRATION: "REGISTRATION",
	OFFICIAL_NUMBER: "OFFICIAL_NUMBER", IMO: "IMO", CALL_SIGN: "CALL_SIGN", MMSI: "MMSI"})
export {SUBSCRIPTION_NAME, BROKER_SUBSCRIPTION_LEVEL, PERSONAL_SUBSCRIPTION_LEVEL, MARINA_SUBSCRIPTION_LEVEL,
	PROVIDER_SUBSCRIPTION_LEVEL, SUBSCRIPTION_TYPE, PRICING_STRUCTURE_UNITS, ORDER_BUTTON, TERMS_OF_SERVICE,
	SUBSCRIPTION_INTERVAL, PRICING_MODEL, PARALIAN_PRODUCT_NAME, SUBSCRIPTION_STATUS, SUBSCRIPTION_FREQUENCY,
	VESSEL_SYSTEM, UPKEEP_CATEGORY, DOCUMENT_TYPE, DOCUMENT_CONTAINER, VERIFICATION_STATE, ACCOUNT_STATE, ACCOUNT_TYPE,
	VERIFICATION_TYPE, ORGANIZATION_TYPE, ORGANIZATION_CATEGORY, ORGANIZATION_STRUCTURE, VESSEL_TYPE, FUEL, FLUID,
	ENGINE_CLASS, PARALIAN_LANGUAGE, UNIT_SYSTEM, DAY_DATE, RECURRENCE, MONTH, WEEKDAY, ORDINAL, ORDER_TYPE, REQUEST_STATUS,
	ADDRESS_TYPE, IMAGE_CATEGORY, SERVER_RESPONSE_STATE, TARGET_TYPE, LOGIN_TYPE, DOCUMENT_TYPE_MINIMAL,
	CONTACT_INFO_TYPE, PHYSICAL_ACCESS, ORDER_ACCESS, ORDER_STATE, STANDARD_ACCESS, COUNTRY_DIVISION, ENGINE_ROTATION,
	ID_TYPE, VESSEL_IDENTIFIER, ACCEPT_REJECT, ORDER_CHARGE_STATE, ORDER_RECURRENCE_STATE, EMPLOYEE_SERVICE_ROLE}