import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {ID_TYPE, SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import {setAccountDefaultVessel, vesselAccessRescind} from "../../../../globals/functions/client/serverFunctions"
import MobileFontAwesome from "../../MobileFontAwesome"
import MobileImage from "../../account/MobileImage"
import Modal from "../../../../globals/components/Modal"
import MobileVesselInviteDecline from "../invite/MobileVesselInviteDecline"
import Vessel from "../../../../globals/classes/shared/Vessel"
import Image from "../../../../globals/classes/shared/Image"
import imagePlaceholder from "../../../../images/ship-duotone.svg"

const MobileVesselsFleetCard = props => {
	const [showModal, setShowModal] = useState(false)
	const vessel = new Vessel(props.vessel)
	const accesses = vessel.access.account
	const account = accesses.idType === ID_TYPE.ACCOUNT ? accesses.id : null
	const organization = accesses.idType === ID_TYPE.ORGANIZATION ? accesses.id : null
	/* TODO: consider removing unlink icon for vessels where account is verified owner */
	const registration = vessel.registration || vessel.documentation || vessel.imo || vessel.hull.HIN || ""
	const isInvitation = vessel.access.individuals.length === 0 && vessel.access.organizations.length === 0
	const unlink = props.canEdit && !isInvitation ?
		<MobileFontAwesome icon={ICONS.UNLINK} style={{fontSize: "3vh"}} onClick={async () => {
		const confirmed = window.confirm(translate(DICTIONARY.UNLINK_VESSEL_QUERY.X))
		let response, submitted = false, ok = true
		if (confirmed) {
			if (accesses.isOwner) {
				response = await vesselAccessRescind(vessel.id, "ownership", account, organization)
				submitted = true
				if (response.status !== SERVER_RESPONSE_STATE.SUCCESS) {ok = false}
			} else {
				if (accesses.orders.access.length > 0) {
					response = await vesselAccessRescind(props.id, "orders", account, organization, accesses.orders.provider)
					submitted = true
					if (response.status !== SERVER_RESPONSE_STATE.SUCCESS) {ok = false}
				}
				if (accesses.data.limitedProfile.length + accesses.data.fullProfile.length > 0) {
					response = await vesselAccessRescind(props.id, "data", account, organization)
					submitted = true
					if (response.status !== SERVER_RESPONSE_STATE.SUCCESS) {ok = false}
				}
				if (accesses.physical.access.length > 0) {
					response = await vesselAccessRescind(props.id, "physical", account, organization, accesses.physical.marina)
					submitted = true
					if (response.status !== SERVER_RESPONSE_STATE.SUCCESS) {ok = false}
				}
			}
			if (ok && submitted) {
				props.unlinkVessel(vessel.id)
			}
		}
	}}/> : <></>
	const primary = props.canEdit && !isInvitation ?
		props.primary === props.vessel.id ?
			<div className={"paralian-theme-level-1-flipped"} style={{padding: "1vh", borderRadius: "1vh",
				margin: "2vh 0vh", fontSize: "2vh"}}>
				<MobileFontAwesome icon={ICONS.STAR[1]}	label={translate(DICTIONARY.DEFAULT.X)}
					style={{fontSize: "2vh", marginRight: "1vh"}}	onClick={() => {
					newMessage("primary-vessel", translate(DICTIONARY.PRIMARY_VESSEL.X),
						translate(DICTIONARY.PRIMARY_VESSEL_MESSAGE.X),	"w3-pale-blue", ICONS.SHIP)}}/>
				<span>{" " + translate(DICTIONARY.PRIMARY.X)}</span>
			</div> :
			<div className={"paralian-theme-level-1 w3-ripple"} style={{padding: "1vh", borderRadius: "1vh",
				margin: "2vh 0vh", fontSize: "2vh"}}
			 	onClick={async () => {
					const confirmed = window.confirm(translate(DICTIONARY.PRIMARY_VESSEL_QUERY.X))
					if (confirmed) {
						const response = await setAccountDefaultVessel(props.vessel.id)
						if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
							props.setPrimaryVessel(props.vessel.id)
						}
					}}}>
				<i>{translate(DICTIONARY.SET_AS_PRIMARY.X) + " "}</i>
				<MobileFontAwesome icon={["far", ICONS.STAR[1]]} label={translate(DICTIONARY.SET_AS_PRIMARY.X)}
					style={{fontSize: "2vh", marginLeft: "1vh"}}/>
			</div> : <></>
	const confirmOwnership = isInvitation && !props.areCustomers ?
		<div className={"paralian-theme-level-1 w3-ripple"} style={{padding: "1vh",
			margin: "2vh 0vh 1vh 0vh", fontSize: "2vh"}} onClick={async () => {
			const confirmed = window.confirm(translate(DICTIONARY.CONFIRM_OWNERSHIP_PROMPT.X))
			if (confirmed) {
				/* TODO: confirm ownership serverFunction */
				const response = await setAccountDefaultVessel(props.vessel.id)
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					props.setPrimaryVessel(props.vessel.id)
				}
			}}}>
			<i>{translate(DICTIONARY.CONFIRM_OWNERSHIP.X)}</i>
				<MobileFontAwesome icon={ICONS.CHECK_CIRCLE} label={translate(DICTIONARY.CONFIRM_OWNERSHIP.X)}
					style={{fontSize: "2vh", marginLeft: "1vh"}}/>
		</div> : <></>
	const declineOwnership = isInvitation && !props.areCustomers ?
		<div className={"w3-orange w3-ripple"} style={{padding: "1vh",
			margin: "1vh 0vh", fontSize: "2vh"}} onClick={async () => {
			const confirmed = window.confirm(translate(DICTIONARY.DECLINE_OWNERSHIP_PROMPT.X))
			if (confirmed) {
				/* TODO: decline ownership serverFunction */
				const response = await setAccountDefaultVessel(props.vessel.id)
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					props.setPrimaryVessel(props.vessel.id)
				}
			}}}>
			<i>{translate(DICTIONARY.DECLINE_OWNERSHIP.X)}</i>
			<MobileFontAwesome icon={ICONS.BAN} label={translate(DICTIONARY.DECLINE_OWNERSHIP.X)}
				style={{fontSize: "2vh", marginLeft: "1vh"}}/>
		</div> : <></>
	/* TODO: show name, brand, model, year for invites (or all?) */
	const modal = showModal ? <Modal icon={ICONS.BAN} title={translate(DICTIONARY.DECLINE_OWNERSHIP.X)} padding={"0vh"}
 		onClick={() => setShowModal(false)} body={MobileVesselInviteDecline}/> : <></>
	return <>
		{modal}
		<div className={"paralian-mobile-info-card"}>
		<div className={"paralian-mobile-info-card-header"} style={{display: "inline-flex", width: "100%",
			textAlign: "center", marginBottom: "1vh"}}>
			<div style={{minWidth: "10%"}}>
				{unlink}
			</div>
			<div style={{width: "100%", padding: "0.5vh"}}>
				<i>{vessel.name.display}</i>
			</div>
			<div style={{minWidth: "10%"}}>
				{ isInvitation && !props.areCustomers ? <></> :
					<MobileFontAwesome icon={ICONS.RANDOM} style={{fontSize: "3vh"}} label={translate(DICTIONARY.SWITCH_VESSEL.X)}
						onClick={() => {
							try {
								// console.log("vessel id", vessel.id)
								props.setIndex(vessel.id)
							} catch (e) {
								console.log(e)
							}
						}}/>
				}
			</div>
		</div>
		{primary}
		<div style={{display: "inline-flex", width: "100%", maxWidth: "36vh"}}>
			<MobileImage image={new Image(vessel.image)} size={"10vh"} alt={"profile"} style={{margin: "auto 1vh"}}
				placeholder={imagePlaceholder} id={`vessel-${vessel.id}-img`}/>
			<div style={{width: "100%", textAlign: "right", margin: "auto"}}>
				<div style={{fontSize: "2vh"}}>
					{registration}
				</div>
				<div style={{fontSize: "2vh"}}>
					{vessel.hull.brand}
				</div>
				<div style={{fontSize: "2vh"}}>
					{vessel.hull.model}
				</div>
				<div style={{fontSize: "2vh"}}>
					{vessel.hull.year}
				</div>
			</div>
		</div>
		{confirmOwnership}
		{declineOwnership}
	</div>
</>
}

export default MobileVesselsFleetCard