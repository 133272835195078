import React, {useState} from "react"
import {SERVER_RESPONSE_STATE} from "../../../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {assignOrder} from "../../../../../globals/functions/client/serverFunctions"
import {fadingMessage, translate} from "../../../../../globals/functions/client/localFunctions"
import {constructArray} from "../../../../../globals/functions/shared/local"
import Modal from "../../../../../globals/components/Modal"
import MobileEmployeeCard from "../../../employee/MobileEmployeeCard"
import MobileButtonGeneric from "../../../../shared/input/buttons/MobileButtonGeneric"
import Account from "../../../../../globals/classes/shared/Account"
/**
 *
 * @param {Object} props
 * @param {Organization} props.organization
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {boolean} props.showAssignModal
 * @param {function} props.setShowAssignModal
 * @param {function} props.refreshOrders
 * @return {JSX.Element}
 * @constructor
 */
const AssignOrderModal = props => {
	const [employeeId, setEmployeeId] = useState(-1)
	const [disableButtons, setDisableButtons] = useState(false)
	const order = props.order
	const employees_ = [...props.organization.admins, ...props.organization.employees]
	return props.showAssignModal ? <Modal id={"provider-public-profile"} icon={ICONS.PEOPLE_ARROWS} padding={"0vh"}
		title={translate(DICTIONARY.ASSIGN.X)} onClick={() => props.setShowAssignModal(false)}
		body={<div style={{padding: "2vh"}}>
			{
				constructArray(employees_.filter(v => v.isActive), i => new Account(i)).map(employee =>
					<div key={`order-${order.id}-details-employee-card-assign-${employee.id}`} style={{margin: "1vh 0vh"}}>
						<MobileEmployeeCard employee={employee} className={"paralian-theme-level-2"}
							onClick={() => {
								setEmployeeId(employee.id)
								document.getElementById(`employee-${employee.id}`).style.border = "solid"
								for (let i = 0; i < employees_.length; i++) {
									if (employee.id !== employees_[i].id) {
										document.getElementById(`employee-${employees_[i].id}`).style.border = "unset"
									}
								}
							}
						}/>
					</div>)
			}
			<MobileButtonGeneric id={"submit-assign"} type={"button"} className={"paralian-theme-level-1-flipped"}
				padding={"1vh"} style={{width: "100%", borderRadius: "1vh"}} text={{content: translate(DICTIONARY.SUBMIT.X)}} disabled={disableButtons}
				onClick={async () => {
					try {
						if (employeeId === -1) {
							fadingMessage("order-assigned-error", translate(DICTIONARY.MISSING_INPUT.X),
							translate(DICTIONARY.SELECT_EMPLOYEE.X), "w3-pale-yellow", ICONS.EXCLAMATION_TRIANGLE)
							return
						}
						setDisableButtons(true)
						const response = await assignOrder(order.id, employeeId)
						// console.log(response)
						if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
							props.refreshOrders()
							fadingMessage("assign-success-message", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "")
							props.setShowAssignModal(false)
						}
						setDisableButtons(false)
					} catch (e) {
						console.log(e)
						setDisableButtons(false)
					}
			// console.log("assign")
			}}/>
		</div>}/> : <></>
}

export default AssignOrderModal