import React, {useState} from "react"
import {stripePromise} from "../../../index"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE, SUBSCRIPTION_TYPE} from "../../../globals/constants/shared/enumerators"
import {Elements} from "@stripe/react-stripe-js"
import {cancelParalianProduct} from "../../../globals/functions/client/serverFunctions"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileParalianSubscriptionFieldsToggle from "./MobileParalianSubscriptionFieldsToggle"
import MobileImage from "../account/MobileImage"
import MobileInfoCardHeader from "../MobileInfoCardHeader"
import Image from "../../../globals/classes/shared/Image"
/**
 *
 * @param {Object} props
 * @param {Account|Organization} props.parentObject
 * @param {function} props.refresh
 * @param {boolean} props.isOrganization
 * @return {JSX.Element}
 * @constructor
 */
const MobileParalianSubscription = props => {
	const [edit, setEdit] = useState(false)
	// console.log("subscription", props.parentObject.subscription)
	const onSuccess = () => {
		props.refresh().then()
		setEdit(false)
	}
	const onCancel = async () => {
		const confirmed = window.confirm(props.parentObject.subscription.type === SUBSCRIPTION_TYPE.PERSONAL ?
			translate(DICTIONARY.CANCEL_PERSONAL_PREMIUM_QUERY.X) : translate(DICTIONARY.CANCEL_SUBSCRIPTION_QUERY.X))
		if (confirmed) {
			const response = await cancelParalianProduct(props.parentObject.subscription.id, props.isOrganization)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				onSuccess()
			}
		}
	}
	const isTrial = props.parentObject.subscription.status === "TRIAL"
	const content = <Elements stripe={stripePromise}>
		<MobileParalianSubscriptionFieldsToggle edit={edit} canDelete={props.canDelete}
			onSuccess={onSuccess} parentObject={props.parentObject} isOrganization={props.isOrganization}/>
	</Elements>
	const title = props.parentObject.subscription.name
	return <div id={"paralian-products"} className={"w3-animate-opacity paralian-mobile-info-card"}
		style={{textAlign: "left", padding: "2vh"}}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.parentObject.subscription.type ===
			SUBSCRIPTION_TYPE.PERSONAL ? (props.parentObject.subscription.autoBills && props.canEdit) :
			(props.canEdit && !isTrial)}
			onDelete={isTrial ? null : onCancel} labels={[translate(title)]}/>
		<div style={{width: "100%", paddingBottom: "1vh"}}>
			<MobileImage image={new Image({path: props.parentObject.subscription.image})} size={"10vh"} alt={"profile"}
				style={{margin: "auto auto"}} id={"paralian-products-img"}/>
		</div>
		{content}
	</div>
}

export default MobileParalianSubscription