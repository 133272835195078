import React from "react"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {ORDER_CHARGE_STATE} from "../../../../../globals/constants/shared/enumerators"
import {translate} from "../../../../../globals/functions/client/localFunctions"
import Modal from "../../../../../globals/components/Modal"
import MobileOrderDetailsCard from "../../MobileOrderDetailsCard"
import OrderReceiptButtons from "../buttons/OrderReceiptButtons"
/**
 *
 * @param {Object} props
 * @param {Object} props.account
 * @param {Object} props.account.payment
 * @param {Object} props.account.payment.methods
 * @param {Object[]} props.account.payment.methods.cards
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {Object} props.order.charges
 * @param {{order: number, item: number, isAdjustment: boolean, quantity: number, charge: number}[]} props.order.charges.items
 * @param {{requested: number, items: number, taxes: number, total: number}} props.order.charges.total
 * @param {{available: number, captured: number, credits: number, onHold: number, refunded: number, receipts: string[]}} props.order.refunds
 * @param {IndexedArray} props.services
 * @param {Currency} props.currency
 * @param {boolean} props.showOrderDetails
 * @param {function} props.setShowOrderDetails
 * @return {JSX.Element}
 * @constructor
 */
const OrderDetailsModal = props => {
	const order = props.order
	const currency = props.currency
	/*
	const paymentDue = order.charges.total.requested !== null ? Math.round(order.charges.total.total*
		(order.charges.total.requested/
			(props.order.charges.total.items -
				props.order.charges.items.reduce((sum, i) => sum + i.fee, 0)))) : null
	*/
	const paymentDue = order.charges.total.requested
	const markedPaid = [ORDER_CHARGE_STATE.PAID, ORDER_CHARGE_STATE.CANCELLED_PAID].includes(props.order.charge) ?
		<div className={"paralian-theme-level-2"} style={{padding: "2vh", margin: "2vh", borderRadius: "1vh"}}>
			<div style={{fontSize: "6vh"}}>{translate(DICTIONARY.MARKED_PAID.X)}</div>
		</div> : <></>
	const markedUnpaid = [ORDER_CHARGE_STATE.UNPAID, ORDER_CHARGE_STATE.CANCELLED_UNPAID].includes(props.order.charge) ?
		<div className={"paralian-theme-level-2"} style={{padding: "2vh", margin: "2vh", borderRadius: "1vh"}}>
			<div style={{fontSize: "6vh"}}>{translate(DICTIONARY.MARKED_UNPAID.X)}</div>
		</div> : <></>
	console.log("order.charges", order.charges)
	const discount = order.charges.total.discounts.trailblazer + order.charges.total.discounts.recurrence
	return props.showOrderDetails ? <Modal title={translate(DICTIONARY.ORDER_DETAILS.X)} padding={"0vh"}
		icon={ICONS.INFO_CIRCLE} onClick={() => props.setShowOrderDetails(false)}	id={`order-${order.id}-details`}
		body={<>
			{
			order.charges.items.map((item, index) => <MobileOrderDetailsCard key={`order-${order.id}-details-${item.item}`}
				order={order} item={item} discount={order.charges.total.discounts.distributed.length > 0 ?
				order.charges.total.discounts.distributed[index] : 0}	indexedServices={props.services}/>)
			}
			<div className={"paralian-theme-level-2"}	style={{padding: "2vh", margin: "2vh", borderRadius: "1vh"}}>
				<div style={{display: "inline-flex", padding: "1vh 0vh", width: "100%"}}>
					<div style={{textAlign: "left",	width: "100%"}}>{translate(DICTIONARY.SUBTOTAL.X)}</div>
					<div>{currency.asText(order.charges.total.items + (discount > 0 ? discount : 0))}</div>
				</div>
				{order.charges.total.discounts.trailblazer > 0 ?
					<div style={{display: "inline-flex", padding: "1vh 0vh", width: "100%"}}>
						<div style={{textAlign: "left", width: "100%"}}>{translate(DICTIONARY.TRAILBLAZER_DISCOUNT.X)}</div>
						<div>{"-" + currency.asText(order.charges.total.discounts.trailblazer)}</div>
					</div> : <></>
				}
				{order.charges.total.discounts.recurrence > 0 ?
					<div style={{display: "inline-flex", padding: "1vh 0vh", width: "100%"}}>
						<div style={{textAlign: "left", width: "100%"}}>{translate(DICTIONARY.RECURRENCE_DISCOUNT.X)}</div>
						<div>{"-" + currency.asText(order.charges.total.discounts.recurrence)}</div>
					</div> : <></>
				}
				{discount > 0 ?
					<div style={{display: "inline-flex", padding: "1vh 0vh", width: "100%"}}>
						<div style={{textAlign: "left", width: "100%"}}>{translate(DICTIONARY.DISCOUNTED_SUBTOTAL.X)}</div>
						<div>{currency.asText(order.charges.total.items)}</div>
					</div> : <></>
				}
				<div style={{display: "inline-flex", padding: "1vh 0vh", width: "100%"}}>
					<div style={{textAlign: "left", width: "100%"}}>{translate(DICTIONARY.TAXES.X)}</div>
					<div>{currency.asText(order.charges.total.taxes)}</div>
				</div>
				<div style={{display: "inline-flex", padding: "1vh 0vh", width: "100%", fontWeight: "bold"}}>
					<div style={{textAlign: "left", width: "100%"}}>{translate(DICTIONARY.TOTAL.X)}</div>
					<div>{currency.asText(order.charges.total.total)}</div>
				</div>
				<div style={{display: "inline-flex", padding: "1vh 0vh", width: "100%"}}>
					<div style={{textAlign: "left", width: "100%"}}>{translate(DICTIONARY.PAID.X)}</div>
					<div>{currency.asText(order.refunds.captured)}</div>
				</div>
				{order.refunds.refunded > 0 ? <div style={{display: "inline-flex", padding: "1vh 0vh", width: "100%"}}>
					<div style={{textAlign: "left", width: "100%"}}>{translate(DICTIONARY.REFUNDS.X)}</div>
					<div>{"-" + currency.asText(order.refunds.refunded)}</div>
				</div> : <></>}
				{order.refunds.refunded > 0 ? <div style={{display: "inline-flex", padding: "1vh 0vh", width: "100%", fontWeight: "bold"}}>
					<div style={{textAlign: "left", width: "100%"}}>{translate(DICTIONARY.ADJUSTED_TOTAL.X)}</div>
					<div>{currency.asText(order.charges.total.total - order.refunds.refunded)}</div>
				</div> : <></>}
				{
					order.charges.total.requested !== null && (order.buttons.pay || order.buttons.paid) ?
						<>
							<div style={{display: "inline-flex", padding: "1vh 0vh", width: "100%"}}>
								<div style={{textAlign: "left", width: "100%"}}>{translate(DICTIONARY.DISCOUNTED.X)}</div>
								<div>
									{"-" + currency.asText(order.charges.total.total-paymentDue)}
								</div>
							</div>
							<div style={{display: "inline-flex", padding: "1vh 0vh", width: "100%"}}>
								<div style={{textAlign: "left", width: "100%"}}>{translate(DICTIONARY.PAYMENT_DUE.X)}</div>
								<div style={{fontSize: "3vh"}}>
									{currency.asText(paymentDue-order.refunds.captured)}
								</div>
							</div>
						</> : <></>
				}
			</div>
			<OrderReceiptButtons order={order} className={"paralian-theme-level-1-flipped"}/>
			{markedPaid}
			{markedUnpaid}
		</>}/> : <></>
}

export default OrderDetailsModal