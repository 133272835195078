import React from "react"
import ICONS from "../../../globals/constants/client/icons"
import {SUBSCRIPTION_TYPE} from "../../../globals/constants/shared/enumerators"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import Modal from "../../../globals/components/Modal"
import MobileImage from "../account/MobileImage"
import Image from "../../../globals/classes/shared/Image"
import ancreGold from "../../../images/ancre_logo_circle_blue.svg"
import ancreGoldRedDot from "../../../images/ancre_logo_circle_gold_red_dot.svg"
/**
 *
 * @param {Object} props
 * @param {string} props.product
 * @param {function} props.onClick
 * @return {JSX.Element}
 * @constructor
 */
const MobileParalianProductDescription = props => {
	/* TODO: update descriptions */
	try {
		let title, description, image
		switch (props.subscription.type) {
			case SUBSCRIPTION_TYPE.BUSINESS:
				title = translate(DICTIONARY.CUSTOMER_CREDITS.X)
				description = <>
					<p>{translate(DICTIONARY.CUSTOMER_TOKEN_MESSAGE_1.X)}</p>
					<p>{translate(DICTIONARY.CUSTOMER_TOKEN_MESSAGE_2.X)}</p>
					<p>{translate(DICTIONARY.CUSTOMER_TOKEN_MESSAGE_3.X)}</p>
					<p>{translate(DICTIONARY.CUSTOMER_TOKEN_MESSAGE_4.X)}</p>
					<p>{translate(DICTIONARY.CUSTOMER_TOKEN_MESSAGE_5.X)}</p></>
				image = ancreGold
				break
			case SUBSCRIPTION_TYPE.MARINA:
				title = translate(DICTIONARY.CUSTOMER_CREDITS.X)
				description = <>
					<p>{translate(DICTIONARY.CUSTOMER_TOKEN_MESSAGE_1.X)}</p>
					<p>{translate(DICTIONARY.CUSTOMER_TOKEN_MESSAGE_2.X)}</p>
					<p>{translate(DICTIONARY.CUSTOMER_TOKEN_MESSAGE_3.X)}</p>
					<p>{translate(DICTIONARY.CUSTOMER_TOKEN_MESSAGE_4.X)}</p>
					<p>{translate(DICTIONARY.CUSTOMER_TOKEN_MESSAGE_5.X)}</p></>
				image = ancreGold
				break
			case SUBSCRIPTION_TYPE.BROKER:
				title = translate(DICTIONARY.CUSTOMER_CREDITS.X)
				description = <>
					<p>{translate(DICTIONARY.CUSTOMER_TOKEN_MESSAGE_1.X)}</p>
					<p>{translate(DICTIONARY.CUSTOMER_TOKEN_MESSAGE_2.X)}</p>
					<p>{translate(DICTIONARY.CUSTOMER_TOKEN_MESSAGE_3.X)}</p>
					<p>{translate(DICTIONARY.CUSTOMER_TOKEN_MESSAGE_4.X)}</p>
					<p>{translate(DICTIONARY.CUSTOMER_TOKEN_MESSAGE_5.X)}</p></>
				image = ancreGold
				break
			case SUBSCRIPTION_TYPE.PERSONAL:
				title = translate(DICTIONARY.PERSONAL_PREMIUM.X)
				description = <><p>{translate(DICTIONARY.PREMIUM_MEMBERSHIP_MESSAGE_1.X)}</p>
					<p>{translate(DICTIONARY.PREMIUM_MEMBERSHIP_MESSAGE_2.X)}</p>
					<p>{translate(DICTIONARY.PREMIUM_MEMBERSHIP_MESSAGE_3.X)}</p></>
				image = ancreGoldRedDot
				break
			default:
				return <></>
		}
		return <Modal icon={ICONS.INFO_CIRCLE} background={"white"} padding={"0vh"}
			onClick={props.onClick} id={`${props.product}-description`}
			body={<div style={{padding: "2vh 0vh", color: "black", margin: "2vh"}}>
				<div style={{marginBottom: "2vh", display: "inline-flex", fontSize: "2.5vh"}}>
					<MobileImage id={`${props.product}-description-img`} image={new Image({path: image})} size={"5vh"}
						alt={"profile"} style={{margin: "auto auto"}}/>
					<div style={{margin: "auto 2vh"}}>
						{title}
					</div>
				</div>
				<div style={{textAlign: "justify"}}>
					{description}
				</div>
			</div>}/>
	} catch (e) {
		console.log(e)
		return <></>
	}
}

export default MobileParalianProductDescription