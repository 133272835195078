import React, {useState} from "react"
import {ICONS} from "../../../../globals/constants/client/constants"
import {ID_TYPE, SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {vesselAccessRescind} from "../../../../globals/functions/client/serverFunctions"
import MobileFontAwesome from "../../MobileFontAwesome"
import MobileImage from "../../account/MobileImage"
import MobileVesselAccessCardEdit from "./MobileVesselAccessCardEdit"
import MobileVesselAccessCardFields from "./MobileVesselAccessCardFields"
import Accesses from "../../../../globals/classes/shared/Accesses"

/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {Accesses} props.accesses
 * @param {boolean} props.canEdit
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselAccessCard = props => {
	const [edit, setEdit] = useState(false)
	const accesses = new Accesses(props.accesses)
	const account = accesses.idType === ID_TYPE.ACCOUNT ? accesses.id : null
	const organization = accesses.idType === ID_TYPE.ORGANIZATION ? accesses.id : null
	/* TODO: separate orders and physical access by provider */
	/* TODO: show edit on individual owner if source is grantor and individual owner is not verified */
	const onDelete = async () => {
		let ok = true
		if (accesses.orders.any) {
			const response = await vesselAccessRescind(props.id, "orders", account, organization, accesses.orders.provider)
			if (response.status !== SERVER_RESPONSE_STATE.SUCCESS) {ok = false}
		}
		if (accesses.data.any) {
			const response = await vesselAccessRescind(props.id, "data", account, organization)
			if (response.status !== SERVER_RESPONSE_STATE.SUCCESS) {ok = false}
		}
		if (accesses.physical.any) {
			const response = await vesselAccessRescind(props.id, "physical", account, organization, accesses.physical.marina)
			if (response.status !== SERVER_RESPONSE_STATE.SUCCESS) {ok = false}
		}
		if (accesses.isOwner) {
			const response = await vesselAccessRescind(props.id, "ownership", account, organization)
			if (response.status !== SERVER_RESPONSE_STATE.SUCCESS) {ok = false}
		}
		// users are not allowed to modify or delete access to organizations directly, they must cancel the order
		// this is why the account type is always "individuals"
		if (ok) {
			props.onRemove(props.accesses.id, "individuals")
		}
	}
	return <div className={"paralian-mobile-info-card w3-animate-opacity"}>
		<div style={{marginBottom: "2vh", display: "inline-flex", textAlign: "center", width: "100%"}}>
			{edit ? <MobileFontAwesome icon={ICONS.BAN} style={{fontSize: "2.5vh", minWidth: "6vh"}} className={"w3-animate-opacity"}
				onClick={onDelete}/> : <div style={{fontSize: "2.5vh", minWidth: "6vh"}}/>}
			<div style={{width: "100%"}}>
				<MobileImage image={props.accesses.image} size={"10vh"} alt={"profile"} style={{margin: "auto"}}
					showUpload={false} id={"vessel-access-card-img"}/>
			</div>
			{
				!(accesses.isOwner && (accesses.ownership.isVerified || accesses.ownership.response === "CONFIRM")) && props.canEdit ?
					<MobileFontAwesome icon={edit ? ICONS.BACK : ICONS.PENCIL} style={{fontSize: "2.5vh", minWidth: "6vh"}}
					className={"w3-animate-opacity"} onClick={() => setEdit(!edit)}/> :
					<div style={{fontSize: "2.5vh", minWidth: "6vh"}}/>}
		</div>
		<div style={{fontSize: "2.5vh", textAlign: "center"}}>
			<div>{props.accesses.name.given || props.accesses.name.display}</div>
			<div><i style={{paddingTop: "0.5vh", fontSize: "2vh"}}>{props.accesses.username}</i></div>
		</div>
		{edit? <MobileVesselAccessCardEdit id={props.id} onEdit={(access, type) => {
				props.onEdit(access, type)
				setEdit(false)
		}} account={props.account} accesses={props.accesses}/> :
			<MobileVesselAccessCardFields accesses={props.accesses}/>}
	</div>
}

export default MobileVesselAccessCard