import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../globals/constants/client/constants"
import {fadingMessage, msToHrsOrMins, replaceInStorage, translate} from "../../globals/functions/client/localFunctions"
import MobileFontAwesome from "./MobileFontAwesome"
import MobileBusinessRating from "./MobileBusinessRating"
import MobileImage from "./account/MobileImage"
import Modal from "../../globals/components/Modal"
import MobileOrganizationPublicProfile from "./organization/MobileOrganizationPublicProfile"
import Image from "../../globals/classes/shared/Image"
/**
 *
 * @param {Object} props
 * @param {Organization} props.provider
 * @param {Name} props.provider.name
 * @param {Rating} props.provider.rating
 * @param {OrderMetrics} props.provider.orderStatistics
 * @param {Currency} props.provider.currency
 * @param {boolean} props.provider.isTopTierProvider
 * @param {boolean} props.provider.isMarinaRecommended
 * @param {number} props.price - total price for service
 * @param {string} props.marina - Marina's display name
 * @return {JSX.Element}
 * @constructor
 */
const MobileProviderSelectionCard = props => {
	const [showModal, setShowModal] = useState(false)
	// console.log(props.provider)
	if ((typeof props.provider.price !== "number") || !props.provider.organization.name) {return <></>}
	const totalPreDiscounts = props.provider.discounts.none
	const total = props.provider.price
	const totalWithRecurrenceDiscount = total - props.provider.discounts.potential
	const publicProfile = showModal ? <Modal padding={"0vh"} title={props.provider.organization.name.display}
		onClick={() => setShowModal(false)} id={"provider-public-profile"}
		body={<MobileOrganizationPublicProfile id={props.provider.organization.id}
		setAccount={() => {}} color={"paralian-theme-level-1"}/>}/> : <></>
	const responseTimeLower = msToHrsOrMins(props.provider.metrics.responseTime.tenthPercentile ||
		props.provider.metrics.responseTime.min)
	const responseTimeUpper = msToHrsOrMins(props.provider.metrics.responseTime.ninetiethPercentile ||
		props.provider.metrics.responseTime.max)
	// console.log("props.provider.metrics.responseTime", props.provider.metrics.responseTime)
	// console.log("props.provider.price", props.provider.price)
	const content = <div>
		{publicProfile}
		<MobileImage id={`provider-logo-${props.provider.organization.id}`} size={"10vh"} alt={"logo"}
			image={new Image(props.provider.organization.logo)} style={{margin: "0vh auto 1vh auto"}} onClick={() => setShowModal(true)}/>
		<MobileBusinessRating rating={props.provider.metrics.rating} style={{fontSize: "3vh", margin: "0vh 0vh 2vh 0vh"}} padding={"1vh"}/>
		<div className={"paralian-theme-level-3"} style={{padding: "1vh 0vh", borderRadius: "1vh"}}
		 	onClick={() => fadingMessage("averageResponseTime",
			translate(DICTIONARY.AVERAGE_RESPONSE_TIME.X), translate(DICTIONARY.AVERAGE_RESPONSE_TIME_MESSAGE.X),
			"w3-pale-blue", ICONS.HEART_CIRCLE)}>
			{translate(DICTIONARY.TYPICAL_RESPONSE_TIME.X)}
			{/* TODO: change to "typically responds within X to Y time" using average and 2*standard deviation */}
		</div>
		<div style={{padding: "1vh 0vh"}}>
			{translate(DICTIONARY.BETWEEN.X) + " " +
			`${responseTimeLower.number || 1} ` + translate(responseTimeLower.type || "MINUTES") +
			` ${translate(DICTIONARY.AND.X)} ` +
			`${responseTimeUpper.number || 48} ` + translate(responseTimeUpper.type || "HOURS")}
		</div>
		{/*
			<div>{"Accept Rate"}</div>
			<div>{valOrNA(() => props.provider.metrics.acceptRate.sampleAsText)}</div>
			<div>{"Cancel Rate"}</div>
			<div>{valOrNA(() => props.provider.metrics.providerCancelRate.sampleAsText)}</div>
			<div>{"Dispute Rate"}</div>
			<div>{valOrNA(() => props.provider.metrics.disputeRate.sampleAsText)}</div>

		*/}
		{/* props.provider.compliments ? <>
			<div>{"Compliments"}</div>
			<div>{props.provider.compliments}</div>
		</> : <></> */}
		<div className={"paralian-theme-level-3"} style={{padding: "1vh 0vh", borderRadius: "1vh"}}>
			{translate(DICTIONARY.TOTAL.X)}
		</div>
		<div style={{padding: "1vh 0vh", fontSize: "3vh", textDecoration: totalPreDiscounts > total ? "line-through" : "none"}}>
			{props.provider.organization.currency.asText(totalPreDiscounts > total ? totalPreDiscounts : total)}
		</div>
		{totalPreDiscounts > total ? <div style={{padding: "1vh 0vh",	fontSize: "5vh"}}>
			{props.provider.organization.currency.asText(total)}
		</div> : <></>}
		{totalWithRecurrenceDiscount < total ? <div style={{padding: "1vh 0vh",	fontSize: "3vh"}}>
			{`Make Recurring and Save ${totalPreDiscounts > total ? "an additional " : ""}${props.provider.organization.currency.asText(props.provider.discounts.potential)}`}
		</div> : <></>}
	</div>
	return <div className={"paralian-mobile-info-card"} style={props.selected === props.provider.organization.id ?
		{border: "solid"} : null} onClick={() => {
		const id = props.provider.organization.id
		if (props.selected === id) {
			props.setSelected(-1)
		} else {
			props.setSelected(id)
			replaceInStorage("order", "provider", {
					id: id,
					label: props.provider.organization.name.display,
					currency: props.provider.organization.currency,
					stripeId: props.provider.organization.connectId
				}, "session")
				replaceInStorage("order", "total", props.provider.price, "session")
			}
	}}>
		<div className={"paralian-mobile-info-card-header"} style={{display: "inline-flex", width: "100%", marginBottom: "1vh"}}>
			<div style={{minWidth: "20%"}}>
				{props.isFavorite || props.provider.isFavorite ?
					<MobileFontAwesome icon={ICONS.HEART_CIRCLE} style={{fontSize: "3vh", paddingRight: "2vh", opacity: "1"}}
						label={translate(DICTIONARY.FAVORITE_PROVIDER.X)}
						onClick={() => fadingMessage("favorite", translate(DICTIONARY.FAVORITE.X),
							translate(DICTIONARY.FAVORITE_PROVIDER_MESSAGE.X), "w3-pale-blue", ICONS.HEART_CIRCLE)
						}/> : <></>}
			</div>
			<div style={{width: "100%", padding: "0.5vh 0vh"}}>
				<i>{props.provider.organization.name.display}</i>
			</div>
			<div style={{minWidth: "20%"}}>
				{props.provider.isMarinaRecommended && props.marina ?
					<MobileFontAwesome icon={ICONS.TROPHY} style={{fontSize: "3vh", paddingRight: "2vh", opacity: "1"}}
						label={translate(DICTIONARY.RECOMMENDED_PROVIDER.X)}
						onClick={() => fadingMessage("marinaRecommended", translate(DICTIONARY.RECOMMENDED_PROVIDER.X),
						`${props.marina}${translate(DICTIONARY.RECOMMENDED_PROVIDER_MESSAGE.X)}`, "w3-pale-blue",
							ICONS.AWARD)
					}/> : <></>}
				{props.provider.isTopTierProvider ?
					<MobileFontAwesome icon={ICONS.AWARD} style={{fontSize: "3vh", opacity: "1"}}
						label={translate(DICTIONARY.TOP_TIER_PROVIDER.X)}
						onClick={() => fadingMessage("paralianRecommended", translate(DICTIONARY.TOP_TIER_PROVIDER.X),
						translate(DICTIONARY.TOP_TIER_PROVIDER_MESSAGE.X), "w3-pale-blue", ICONS.AWARD)
						}/> : <></>}
			</div>
		</div>
		{content}
	</div>
}

export default MobileProviderSelectionCard